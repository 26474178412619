import { colorSteelDarkest, mediaLaptop } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Branding from "../Branding";
import Navigation from "../Navigation";
import { useRouter } from "next/router";

interface Props {
  backgroundColor?: string;
  className?: string;
  mode?: "light" | "dark";
}

export const HEIGHT = "4rem";
export const HEIGHT_IN_PIXELS = 4 * 16;

const TopBar: FunctionComponent<Props> = ({
  backgroundColor = colorSteelDarkest,
  className,
  mode = "dark",
}) => {
  const { locale } = useRouter();
  return (
    <div
      className={className}
      css={css`
        align-items: center;
        background: ${backgroundColor};
        box-sizing: border-box;
        display: flex;
        gap: 1.5rem;
        height: ${HEIGHT};
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 100;

        @media (min-width: ${mediaLaptop}) {
          gap: 3.5rem;
        }
      `}
    >
      <div
        css={css`
          transform: translateY(2px);
        `}
      >
        <Branding height={"2rem"} mode={mode} />
      </div>

      <Navigation locale={locale} mode={mode} />
    </div>
  );
};

export default TopBar;
