import { colorBlueMedium, layoutWidth878 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { CSSProperties } from "react";
import type { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-dom";

import Text from "../Text";

type Props = CurrentRefinementsProvided & {
  attributes?: string[];
  children: string;
  className?: string;
  style?: CSSProperties;
};

/**
 * An Algolia-connected button for clearing
 * all currently-applied filters.
 */
export const ClearFiltersButton: FunctionComponent<Props> = ({
  attributes,
  children,
  items,
  refine,
  style,
  className = "",
}) => {
  const matchedItems = attributes
    ? items.filter((item) => {
        return attributes.includes(item.attribute);
      })
    : items;

  const handleClick = () => {
    refine(matchedItems);
  };

  return matchedItems.length ? (
    <button
      css={css`
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        margin: 0;
        padding: 0;
        @media (max-width: ${layoutWidth878}) {
          align-self: flex-end;
        }

        :hover {
          text-decoration: underline;
          text-decoration-color: ${colorBlueMedium};
        }
      `}
      onClick={handleClick}
      type={"button"}
    >
      <Text
        as={"span"}
        className={className}
        color={"blue"}
        size={"xsmall"}
        style={style}
        weight={"semibold"}
      >
        {children}
      </Text>
    </button>
  ) : null;
};

export default connectCurrentRefinements(ClearFiltersButton);
