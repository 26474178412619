import { css } from "@emotion/react";
import type { FunctionComponent, HTMLProps } from "react";
import type { MenuProvided, Refinement } from "react-instantsearch-core";

import RadioButton from "../Form/RadioButton";
import { ConnectedMenuFilter, ConnectedRefinementListFilter } from "./Filter";
import { Header } from "./Header";
import type { Facets, GenericItem } from "./types";
import { InputType } from "./types";
import { sortByValues } from "../../utils/sort-by-values";

export type ExcludeRefinements = (items: Refinement[]) => Refinement[];

interface Props {
  collapsible?: boolean;
  /**
   * A function that will be pass to the clear filters
   * button as `transformItems`:
   * https://www.algolia.com/doc/api-reference/widgets/clear-refinements/react/#widget-param-transformitems
   *
   * Use this to filter attributes that should not be
   * cleared from refinements when the user hits the
   * "Clear all" refinements button.
   */
  excludeRefinements?: ExcludeRefinements;

  facets: Facets;

  title?: string;
}

type DivProps = Pick<HTMLProps<HTMLDivElement>, "className">;

/**
 * Nested lists of checkboxes that enable users
 * to filter by the facets defined on Algolia.
 */
const Filters: FunctionComponent<Props & DivProps> = ({
  excludeRefinements,
  facets,
  className,
  title,
  collapsible = true,
}) => {
  return (
    <div className={className}>
      <Header excludeRefinements={excludeRefinements} title={title} />

      <ul
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {facets.map((facet) => {
          const {
            attribute,
            label,
            inputType = InputType.Checkbox,
            order,
          } = facet;

          const transformItems =
            order && order.length > 0
              ? (items: GenericItem[]) =>
                  sortByValues(
                    order,
                    items as MenuProvided["items"],
                  ) as GenericItem[]
              : undefined;

          return inputType === InputType.Checkbox ? (
            <ConnectedRefinementListFilter
              attribute={attribute}
              collapsible={collapsible}
              key={attribute}
              label={label}
              transformItems={transformItems}
            />
          ) : (
            <ConnectedMenuFilter
              attribute={attribute}
              collapsible={collapsible}
              input={RadioButton}
              key={attribute}
              label={label}
              transformItems={transformItems}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Filters;
